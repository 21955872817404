import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getBusinessById } from '../../services/business';
import { logReviewLinkVisit } from '../../services/appointment';
import { getEmployeeById } from '../../services/employee';

function useLogReview(reviewLinkId: string) {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['review-link', reviewLinkId],
    queryFn: async () => {
      const reviewLinkResult = await logReviewLinkVisit(reviewLinkId!);
      if (!reviewLinkResult) throw new Error('Review link not found');

      const reviewLinkEmployee = reviewLinkResult.employeeId
        ? await getEmployeeById(reviewLinkResult.employeeId)
        : null;
      const reviewLinkBusiness = reviewLinkResult.businessId
        ? await getBusinessById(reviewLinkResult.businessId)
        : null;
      if (!reviewLinkBusiness && !reviewLinkEmployee) {
        throw new Error('Review link not found');
      }

      if (reviewLinkBusiness && !reviewLinkBusiness.reviewLink) {
        navigate(`/shop/${reviewLinkBusiness.urlPath}`);
      }

      if (reviewLinkEmployee && !reviewLinkEmployee.reviewLink) {
        navigate(`/artist/${reviewLinkEmployee.urlPath}`);
      }

      return {
        business: reviewLinkBusiness || null,
        employee: reviewLinkEmployee || null,
        reviewLink: reviewLinkResult,
      };
    },
    enabled: !!reviewLinkId,
    staleTime: 0,
    gcTime: 0,
  });

  return {
    data,
    isLoading,
    isError,
  };
}

export default useLogReview;
