import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Employee } from '../../interfaces';

interface GoodExperienceProps {
  entityName?: string;
  employee?: Employee | null;
  handleResponseChange: (value: 'positive' | 'negative') => void;
}

export default function GoodExperience({
  entityName,
  employee,
  handleResponseChange,
}: GoodExperienceProps) {
  return (
    <>
      <Box className="review-header">
        <div className="review-location">
          <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
          <Typography variant="h5" style={{ color: 'var(--medGrey)' }}>
            {entityName}
          </Typography>
        </div>
        <Typography variant="h1">
          Did you have a good experience {employee ? 'with' : 'at'} {entityName}
          ?
        </Typography>
        <Typography variant="body2">
          We appreciate your feedback so we can continue to improve our
          experience!
        </Typography>
      </Box>
      <RadioGroup
        aria-labelledby="radio-goodExperience"
        name="goodExperience"
        id="good-experience-radio"
        onChange={event =>
          handleResponseChange(event.target.value as 'positive' | 'negative')
        }
        className="radio-box">
        <FormControlLabel
          value="positive"
          control={
            <Radio
              className="radio-input"
              color="secondary"
              inputProps={{ className: 'radio-first-tattoo' }}
            />
          }
          label="Yes"
          className="radio-pill"
        />
        <FormControlLabel
          value="negative"
          control={
            <Radio
              className="radio-input"
              color="secondary"
              inputProps={{ className: 'radio-first-tattoo' }}
            />
          }
          label="No"
          className="radio-pill"
        />
      </RadioGroup>
    </>
  );
}
