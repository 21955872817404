import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { trackUtmAttribution } from '../../services/utm';
import { UpsertUtmAttributionPayload } from '../../types/utm';
import useExistingAttribution from './useExistingAttribution';
import useUser from '../global/useUser';

type UseTrackUTMParams = {
  enabled?: boolean;
} & (
  | { businessId: string }
  | { employeeId: string }
  | { appointmentId: string }
);

const CAMPAIGN_PARAMETER = 'pf_campaign_id';

function useTrackUTM({ enabled = true, ...params }: UseTrackUTMParams) {
  const [searchParams] = useSearchParams();
  const paramSourceCampaignId = searchParams.get(CAMPAIGN_PARAMETER);
  const { user, isLoading: isAuthLoading } = useUser();
  const [existingAttribution, setExistingAttribution] =
    useExistingAttribution();

  const paramIds = params as {
    businessId?: string;
    employeeId?: string;
    appointmentId?: string;
  };

  const { sourceCampaignId, businessId, employeeId, appointmentId } = useMemo(
    () => ({
      sourceCampaignId:
        (existingAttribution
          ? existingAttribution.sourceCampaignId
          : paramSourceCampaignId) || null,
      businessId:
        (existingAttribution
          ? existingAttribution.businessId
          : paramIds.businessId) || null,
      employeeId:
        (existingAttribution
          ? existingAttribution.employeeId
          : paramIds.employeeId) || null,
      appointmentId:
        (existingAttribution
          ? existingAttribution.appointmentId
          : paramIds.appointmentId) || null,
    }),
    [existingAttribution, paramIds, paramSourceCampaignId],
  );

  const isEnabled =
    enabled &&
    !isAuthLoading &&
    !!sourceCampaignId &&
    (!!businessId || !!employeeId || !!appointmentId);

  const existingAttributionRef = useRef(existingAttribution);
  useEffect(() => {
    existingAttributionRef.current = existingAttribution;
  }, [existingAttribution]);

  const upsertUtmAttribution = useCallback(async () => {
    const payload = {
      sourceCampaignId: sourceCampaignId!,
      existingAttributionId: existingAttributionRef.current?.id || undefined,
      appointmentId,
      employeeId,
      businessId,
    } as unknown as UpsertUtmAttributionPayload;

    const isComplete =
      existingAttributionRef.current &&
      ((user?.userId &&
        existingAttributionRef.current.userId === user?.userId) ||
        !user?.userId);
    if (isComplete) return null;

    const attribution = await trackUtmAttribution(payload);

    if (attribution) {
      setExistingAttribution({
        id: attribution.id,
        sourceCampaignId: attribution.sourceCampaignId,
        appointmentId:
          attribution.entity === 'BOOKING' ? attribution.entityId : null,
        employeeId:
          attribution.entity === 'EMPLOYEE' ? attribution.entityId : null,
        businessId: attribution.entity === 'SHOP' ? attribution.entityId : null,
        userId: attribution.userId || null,
      });
    }

    return attribution;
  }, [
    sourceCampaignId,
    appointmentId,
    employeeId,
    businessId,
    user?.userId,
    setExistingAttribution,
  ]);

  const query = useQuery({
    queryKey: [
      'utm-attribution',
      user?.userId,
      sourceCampaignId,
      businessId,
      employeeId,
      appointmentId,
    ],
    queryFn: upsertUtmAttribution,
    enabled: isEnabled,
  });

  return { ...query, isEnabled };
}

export default useTrackUTM;
