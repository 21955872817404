import { Box, FormControlLabel, Checkbox } from '@mui/material';
import { Business, Employee } from '../../interfaces';
import FormHeader from './FormHeader';
import BottomActionBar from '../Global/BottomActionBar';
import EmployeeHeader from '../EmployeeHeader/EmployeeHeader';
import PorterFooter from '../Global/PorterFooter';
import { FIRST_AVAILABLE_ARTIST_ID } from '../../constants/form';

interface ArtistSelectionScreenProps {
  business?: Business;
  selectedArtists: string[];
  handleArtistSelection: (artistId: string) => void;
  handleContinue: () => void;
}

export function ArtistSelectionScreen({
  business,
  selectedArtists,
  handleArtistSelection,
  handleContinue,
}: ArtistSelectionScreenProps) {
  const toggleArtistSelection = (artistId: string) => {
    handleArtistSelection(artistId);
  };

  const sortedEmployees = business?.employees?.sort((a, b) => {
    const dateA = new Date(a.createdAt as string);
    const dateB = new Date(b.createdAt as string);
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <Box
      style={{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
        paddingTop: '40px',
      }}>
      <EmployeeHeader business={business} />
      <FormHeader
        header="Which artist(s) are you interested in getting tattooed by?"
        subHeader="Please select all that apply"
      />
      <Box className="input-container">
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '120px',
          }}>
          <FormControlLabel
            key={FIRST_AVAILABLE_ARTIST_ID}
            value={FIRST_AVAILABLE_ARTIST_ID}
            onChange={() => toggleArtistSelection(FIRST_AVAILABLE_ARTIST_ID)}
            control={
              <Checkbox
                className="radio-input"
                color="secondary"
                checked={selectedArtists.includes(FIRST_AVAILABLE_ARTIST_ID)}
              />
            }
            label="First available"
            className={`radio-pill ${
              selectedArtists.includes(FIRST_AVAILABLE_ARTIST_ID)
                ? 'checked'
                : ''
            }`}
          />
          {sortedEmployees?.map(
            (artist: Employee) =>
              artist.isBookable && (
                <FormControlLabel
                  key={artist.employeeId}
                  value={artist.employeeId}
                  onChange={() => toggleArtistSelection(artist.employeeId)}
                  control={
                    <Checkbox
                      className="radio-input"
                      color="secondary"
                      checked={selectedArtists.includes(artist.employeeId)}
                    />
                  }
                  label={artist.displayName}
                  className={`radio-pill ${
                    selectedArtists.includes(artist.employeeId) ? 'checked' : ''
                  }`}
                />
              ),
          )}
        </Box>
      </Box>
      <BottomActionBar
        primaryText="Continue"
        primaryDisabled={selectedArtists.length === 0}
        primaryAction={handleContinue}
      />
      <PorterFooter />
    </Box>
  );
}
