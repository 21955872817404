import { useMutation } from '@tanstack/react-query';
import { postReview } from '../../services/appointment';

interface SubmitReviewOptions {
  reviewLinkId: string;
  onSuccess?: () => void | Promise<void>;
  onError?: (error?: Error) => void | Promise<void>;
}

export const useReviewSubmission = ({
  reviewLinkId,
  onSuccess,
  onError,
}: SubmitReviewOptions) => {
  const reviewSubmissionMutation = useMutation({
    mutationFn: ({ rating, feedback }: { rating: number; feedback?: string }) =>
      postReview(reviewLinkId, rating, feedback),
    onSuccess,
    onError,
  });

  return {
    reviewSubmissionMutation,
    isPending: reviewSubmissionMutation.isPending,
    isSuccess: reviewSubmissionMutation.isSuccess,
    isError: reviewSubmissionMutation.isError,
    error: reviewSubmissionMutation.error,
  };
};
