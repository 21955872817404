import {
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
  useTheme,
} from '@mui/material';

interface Props {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  name?: string;
}

function MarketingConsent({
  checked,
  onChange,
  name = 'isMarketingOptIn',
}: Props) {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={name}
          name={name}
          sx={{
            padding: 0,
            margin: 0,
          }}
          checked={!!checked}
          onChange={onChange}
        />
      }
      sx={{
        alignItems: 'flex-start',
        gap: 1,
        m: 0,
      }}
      label={
        <Typography variant="body2" color={theme.palette.medGrey.main}>
          I consent to receive marketing text messages from Porter at the number
          provided. Msg & data rates may apply. Message frequency varies.
          Unsubscribe at any time by replying STOP. Text HELP for help. You can
          read more about SMS messaging in our{' '}
          <Link
            fontWeight={700}
            color="inherit"
            sx={{
              ':hover': {
                color: theme.palette.darkGrey.main,
              },
            }}
            href="https://www.getporter.io/privacy-policy"
            target="_blank">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            fontWeight={700}
            color="inherit"
            sx={{
              ':hover': {
                color: theme.palette.darkGrey.main,
              },
            }}
            href="https://www.getporter.io/terms-of-use"
            target="_blank">
            Terms of Use
          </Link>
          .
        </Typography>
      }
    />
  );
}

export default MarketingConsent;
