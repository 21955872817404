import { createContext } from 'react';
import { User } from '../../interfaces';

export interface AppState {
  user: {
    data: User | null;
    isLoading: boolean;
    selectedBusiness: string;
  };
  utm: {
    id: string;
    sourceCampaignId: string | null;
    employeeId: string | null;
    businessId: string | null;
    appointmentId: string | null;
    userId: string | null;
  } | null;
}

interface AppContextType {
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>; // Replace 'any' with the actual action type if you have one
}

const getExistingAttribution = () => {
  const obj = sessionStorage.getItem('utm_attribution');
  if (!obj || obj === 'null') return null;
  const parsed = JSON.parse(obj) as AppState['utm'];
  if (
    !parsed?.id ||
    !parsed?.sourceCampaignId ||
    (!parsed.employeeId && !parsed.businessId && !parsed.appointmentId)
  )
    return null;
  return parsed;
};

export const initialState: AppState = {
  user: {
    data: null,
    isLoading: true,
    selectedBusiness: sessionStorage.getItem('selectedBusiness') || '',
  },
  utm: getExistingAttribution(),
};

export type AppStateAction =
  | {
      type: 'SET_USER';
      payload: Partial<AppState['user']>;
    }
  | {
      type: 'SET_EXISTING_ATTRIBUTION';
      payload: AppState['utm'];
    };

// Providing an initial value for dispatch as well (a no-op function in this case)
const defaultContextValue: AppContextType = {
  state: initialState,
  dispatch: () => {},
};

export const AppContext = createContext<AppContextType>(defaultContextValue);
