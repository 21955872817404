import { UtmAttributions } from '@prisma/client';
import API_URL from '../apiconfig';
import axios from './api';
import { UpsertUtmAttributionPayload } from '../types/utm';

export async function trackUtmAttribution(
  payload: UpsertUtmAttributionPayload,
) {
  try {
    const response = await axios.post(`${API_URL}/v1/utm`, payload);
    return response.data?.result as UtmAttributions & {
      sourceCampaignId: string;
    };
  } catch (error) {
    throw new Error('Failed to track UTM attribution');
  }
}
