/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { differenceInYears, format } from 'date-fns';
import './Form.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Roles } from '@prisma/client';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, Employee, Image, Business } from '../../interfaces';
import {
  createAppointment,
  createGeneralRequest,
  moveImages,
} from '../../services/appointment';
import { createUpdateCustomer } from '../../services/customer';
import LoadingModal from '../Global/LoadingModal';
import { getChoices } from '../../services/choices';
import ConfirmationModal from '../Global/ConfirmationModal';
import FormHeader from './FormHeader';
import useUser from '../../hooks/global/useUser';
import { getBooleanStringText } from '../../utils/appointment';
import { FORM_STEPS } from '../../constants/form';

type Props = {
  formValues: FormValues;
  employee: Employee | undefined;
  business: Business | undefined;
  setStep: any;
  setClickedEdit: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentCity: string;
  appointmentTimezone: string;
  setNameStep: React.Dispatch<React.SetStateAction<number>>;
  selectedArtists?: string[];
};

function Review({
  formValues,
  employee,
  business,
  setStep,
  setClickedEdit,
  appointmentCity,
  appointmentTimezone,
  setNameStep,
  selectedArtists,
}: Props) {
  const { user } = useUser();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [firstTattooText, setFirstTattooText] = React.useState('');
  const [newCustomerText, setNewCustomerText] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [sizeOptions, setSizeOptions] = React.useState<any[]>();
  const [sizeLoading, setSizeLoading] = React.useState(true);
  const [isArtistModalOpen, setIsArtistModalOpen] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const tempSizeOptions = await getChoices('TATTOO_SIZE').catch(() => {});
      setSizeOptions(tempSizeOptions);

      setSizeLoading(false);
    }
    if (sizeLoading) {
      fetchData();
    }
  }, [sizeLoading]);

  // eslint-disable-next-line no-nested-ternary
  const entityName = employee
    ? employee?.displayName ||
      `${employee?.user.firstName || ''} ${
        employee?.user.lastName || ''
      }`.trim()
    : business
    ? business.name
    : '';

  const questionValues = {
    header: 'Submit your booking request',
    subHeader:
      "Once your request has been reviewed, you'll receive a text message with details on how to book your appointment.",
  };

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Submitting booking request...';

  function printImages(images: Image[]) {
    const imageURLElems: React.ReactNode[] = [];
    images.forEach(image => {
      imageURLElems.push(
        <Grid item md={4} key={image.name}>
          <img className="image-upload" src={image.url} alt="Uploaded" />
        </Grid>,
      );
    });
    return imageURLElems;
  }

  function printItem(label: string, value: any, step?: number) {
    if (label === 'Reference Images' || label === 'Area Images') {
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          <Grid container spacing={2}>
            {printImages(value)}
          </Grid>
          <Button
            className="review-link"
            id={`edit-${step}`}
            onClick={() => {
              setStep(step);
              setClickedEdit(true);
            }}>
            Edit
          </Button>
        </div>
      );
    }
    if (label === 'Phone Number') {
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          <Typography variant="body1">{value}</Typography>
        </div>
      );
    }

    return (
      <div className="review-item">
        <Typography variant="h3">{label}</Typography>
        <Typography variant="body1">{value}</Typography>
        <Button
          className="review-link"
          id={`edit-${step}`}
          onClick={() => {
            setStep(step);
            setClickedEdit(true);
            if (label === 'Appointment City') {
              setNameStep(2);
            } else if (label === 'Name') {
              setNameStep(1);
            }
          }}>
          Edit
        </Button>
      </div>
    );
  }

  const resetForm = React.useCallback(() => {
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('refImageCount');
    sessionStorage.removeItem('areaImageCount');
    sessionStorage.removeItem('selectedArtists');
    sessionStorage.removeItem('businessId');
    sessionStorage.removeItem('city');
    Object.keys(formValues).forEach(key => {
      sessionStorage.removeItem(key);
    });
  }, [formValues]);

  const submitAppointment = React.useCallback(
    async (force = false) => {
      if (!user) {
        return;
      }

      if (!business && !employee) {
        return;
      }

      // customer is an employee
      const isArtistAsCustomer = (
        [Roles.ADMIN, Roles.EMPLOYEE, Roles.SHOP_MANAGER] as Roles[]
      ).includes(user.role as Roles);

      if (isArtistAsCustomer && !force) {
        setIsArtistModalOpen(true);
        return;
      }

      setLoading(true);
      setModalOpen(true);

      if (
        formValues.birthday &&
        differenceInYears(new Date(), new Date(formValues.birthday)) < 18
      ) {
        setLoading(false);
        setError(true);
        setErrorMessage(
          'Must be 18 years or older to submit an appointment request through Porter. Please reach out to this tattoo artist directly if you are under 18 and want a tattoo with parental supervision',
        );
        setModalOpen(false);
        return;
      }

      const customerResponse = await createUpdateCustomer(
        formValues,
        user,
      ).catch(() => {
        setError(true);
        setModalOpen(false);
        setLoading(false);
      });

      if (customerResponse) {
        const refImgsId = await moveImages(formValues.refImages, user);
        const areaImgsId = await moveImages(formValues.areaImages, user);

        let appointmentResponse;
        if (employee) {
          appointmentResponse = await createAppointment(
            formValues,
            employee,
            user,
            refImgsId,
            areaImgsId,
            appointmentCity,
            appointmentTimezone,
          ).catch((appointmentError: any) => {
            if (
              appointmentError.message ===
              'Cannot create appointment as employee'
            ) {
              setErrorMessage(
                'Can not submit request with an Artist account. Please try again with a different phone number or reach out to our support team.',
              );
            }

            setLoading(false);
            setModalOpen(false);
            setError(true);
          });
        } else if (business) {
          appointmentResponse = await createGeneralRequest(
            formValues,
            business,
            user,
            refImgsId,
            areaImgsId,
            selectedArtists,
          ).catch(() => {
            setLoading(false);
            setModalOpen(false);
            setError(true);
          });
        }

        // If the booking request was submitted, redirect the user to the new page
        if (appointmentResponse) {
          if (employee) {
            window._cio.track('Submit Booking Request', {
              artist: employee.displayName,
            });

            window._cio.identify({
              id: user.userId || user.result?.userId,
              last_appointment_id: appointmentResponse.appointmentId,
              last_employee_display_name: employee.displayName,
            });
          } else if (business) {
            window._cio.track('Submit Booking Request', {
              shop: business.name,
            });

            window._cio.identify({
              id: user.userId || user.result?.userId,
              last_appointment_id: appointmentResponse.appointmentId,
              last_employee_display_name: business.name,
            });
          }

          resetForm();

          window.location.replace(
            `/booking/${
              appointmentResponse.appointmentId || appointmentResponse.requestId
            }`,
          );
        } else {
          setModalOpen(false);
          setLoading(false);
        }
      }
    },
    [
      formValues,
      user,
      business,
      employee,
      appointmentCity,
      appointmentTimezone,
      resetForm,
      selectedArtists,
    ],
  );

  React.useEffect(() => {
    if (formValues.firstTattoo && formValues.firstTattoo !== '') {
      if (formValues.firstTattoo === 'true') {
        setFirstTattooText('Yes');
      } else {
        setFirstTattooText('No');
      }
    }

    if (formValues.newCustomer && formValues.newCustomer !== '') {
      if (formValues.newCustomer === 'true') {
        setNewCustomerText('No');
      } else {
        setNewCustomerText('Yes');
      }
    }
  }, [formValues.firstTattoo, formValues.newCustomer]);

  let tattooSize = formValues.size;
  const sizeItem = sizeOptions?.find(
    (option: any) => option.value === tattooSize,
  );
  if (sizeItem) {
    tattooSize = sizeItem.name;
  }

  return (
    <>
      <Box style={{ flex: '1 1 0', paddingBottom: 165 }}>
        <FormHeader
          header={questionValues.header}
          subHeader={questionValues.subHeader}
        />
        <Stack spacing={2} className="review-section">
          {appointmentCity !== ''
            ? printItem('Appointment City', appointmentCity, FORM_STEPS.NAME)
            : null}

          {formValues.tattooServiceType
            ? printItem(
                'Tattoo Service Type',
                formValues.tattooServiceType,
                FORM_STEPS.TATTOO_SERVICE_TYPE,
              )
            : null}
          {formValues.firstName && formValues.firstName !== ''
            ? printItem(
                'Name',
                `${formValues.firstName} ${formValues.lastName}`,
                FORM_STEPS.NAME,
              )
            : null}
          {printItem('Phone Number', user?.phoneNumber || '')}
          {formValues.email && formValues.email !== ''
            ? printItem('Email', formValues.email, FORM_STEPS.FINAL_DETAILS)
            : null}
          {formValues.birthday
            ? printItem(
                'Birthday',
                format(new Date(formValues.birthday), 'MM/dd/yyyy'),
                FORM_STEPS.FINAL_DETAILS,
              )
            : null}
          {formValues.skinTone
            ? printItem('Skin Tone', formValues.skinTone, FORM_STEPS.SKIN_TONE)
            : null}
          {formValues.firstTattoo && formValues.firstTattoo !== ''
            ? printItem(
                'Is this your first tattoo?',
                firstTattooText,
                FORM_STEPS.FIRST_TATTOO,
              )
            : null}
          {formValues.newCustomer &&
          formValues.newCustomer !== '' &&
          formValues.firstTattoo !== 'true'
            ? printItem(
                `Have you worked with ${entityName} before?`,
                newCustomerText,
                FORM_STEPS.NEW_CUSTOMER,
              )
            : null}
          {formValues.coverUp
            ? printItem(
                'Is this a cover up?',
                getBooleanStringText(formValues.coverUp),
                FORM_STEPS.COVER_UP,
              )
            : null}
          {formValues.ongoingProject
            ? printItem(
                'Is this an ongoing project?',
                getBooleanStringText(formValues.ongoingProject),
                FORM_STEPS.ONGOING_PROJECT,
              )
            : null}
          {formValues.surroundingTattoos
            ? printItem(
                'Are there surrounding tattoos?',
                getBooleanStringText(formValues.surroundingTattoos),
                FORM_STEPS.SURROUNDING_TATTOOS,
              )
            : null}
          {formValues.isEphemeral
            ? printItem(
                'Do you want your tattoo with Ephemeral ink?',
                getBooleanStringText(formValues.isEphemeral),
                FORM_STEPS.EPHEMERAL,
              )
            : null}
          {formValues.availability && formValues.availability !== ''
            ? printItem(
                'General Availability',
                formValues.availability,
                FORM_STEPS.GENERAL_AVAILABILITY,
              )
            : null}

          {formValues.location && formValues.location !== ''
            ? printItem(
                'Body Area',
                formValues.location,
                FORM_STEPS.TATTOO_LOCATION,
              )
            : null}
          {formValues.details && formValues.details !== ''
            ? printItem(
                'Description',
                formValues.details,
                FORM_STEPS.TATTOO_DETAILS,
              )
            : null}
          {formValues.size && formValues.size !== ''
            ? printItem('Size', tattooSize, FORM_STEPS.TATTOO_SIZE)
            : null}
          {formValues.color && formValues.color !== ''
            ? printItem('Color', formValues.color, FORM_STEPS.TATTOO_COLOR)
            : null}
          {formValues.budget && formValues.budget !== 0
            ? printItem('Budget', `$${formValues.budget}`, FORM_STEPS.BUDGET)
            : null}
          {formValues.medicalHistory && formValues.medicalHistory !== ''
            ? printItem(
                'Medical History',
                formValues.medicalHistory,
                FORM_STEPS.MEDICAL_HISTORY,
              )
            : null}

          {formValues.inspiration && formValues.inspiration !== ''
            ? printItem(
                'Tatoo Inspiration',
                formValues.inspiration,
                FORM_STEPS.TATTOO_INSPIRATION,
              )
            : null}
          {formValues.styles && formValues.styles !== ''
            ? printItem(
                'Preferred Tattoo Styles',
                formValues.styles,
                FORM_STEPS.TATTOO_STYLES,
              )
            : null}

          {formValues.refImages && formValues.refImages.length > 0
            ? printItem(
                'Reference Images',
                formValues.refImages,
                FORM_STEPS.REFERENCE_IMAGES,
              )
            : null}
          {formValues.areaImages && formValues.areaImages.length > 0
            ? printItem(
                'Area Images',
                formValues.areaImages,
                FORM_STEPS.AREA_IMAGES,
              )
            : null}
        </Stack>

        {!loading && (
          <BottomActionBar
            primaryText="Submit Request"
            primaryAction={submitAppointment}
          />
        )}
        {loading && (
          <LoadingModal
            header={modalHeader}
            message={modalMessage}
            open={modalOpen}
          />
        )}

        {error ? (
          <Typography variant="body1" className="error">
            {errorMessage ||
              'There was an error submitting your booking request. Please refresh the page and try again. If the error persists, reach out to our support team through the help icon above.'}
          </Typography>
        ) : null}
      </Box>
      <ConfirmationModal
        open={isArtistModalOpen}
        setOpen={setIsArtistModalOpen}
        message="Since you're registered as an artist, submitting this booking request will not change the name, email, or any other personal details on your account. You can submit this request but it will use the name and email from your artist profile."
        negativeAction={() => setIsArtistModalOpen(false)}
        positiveAction={() => {
          setIsArtistModalOpen(false);
          submitAppointment(true);
        }}
        negativeText="Cancel"
        positiveText="Submit Request"
        title="Submit Booking Request as Artist"
      />
    </>
  );
}

export default Review;
