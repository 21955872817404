import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Business, Employee } from '../interfaces';
import { getBusinessByUrlPath } from '../services/business';
import TopImage from '../components/BusinessHeader/TopImage';
import ArtistRow from '../components/BusinessHeader/ArtistRow';
import { PLACES_API_KEY } from '../maps';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import FAQAccordion from '../components/Global/FaqAccordion';
import ServicesBookingList from '../components/Services/ServicesBookingList';
import { DEFAULT_TATTOO_SERVICE_ID } from '../constants/global';
import useTrackUTM from '../hooks/utm/useTrackUTM';

export default function ShopPage() {
  const navigate = useNavigate();

  const [business, setBusiness] = React.useState<Business>();
  const { urlPath } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [addressSearch, setAddressSearch] = React.useState<string>();
  const [sortedEmployees, setSortedEmployees] = React.useState<Employee[]>();

  useTrackUTM({ businessId: business?.businessId || '' });

  React.useEffect(() => {
    async function fetchData() {
      try {
        const tempShop = await getBusinessByUrlPath(urlPath).catch(() => {
          setError(true);
        });

        if (tempShop) {
          setBusiness({
            ...tempShop,
          });
        }
      } catch {
        setError(true);
      }
      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, urlPath]);

  React.useEffect(() => {
    if (business) {
      let tempAddress = '';
      if (business.streetAddress) {
        tempAddress += business.streetAddress;
      }
      if (tempAddress && business.streetAddress2) {
        tempAddress += `, ${business.streetAddress2}`;
      }
      tempAddress += `${business.city}, ${business.state} ${business.zip}`;
      setAddressSearch(tempAddress);

      if (business.employees) {
        const tempSortedEmployees = business.employees.sort((a, b) => {
          const dateA = new Date(a.createdAt as string);
          const dateB = new Date(b.createdAt as string);
          return dateA.getTime() - dateB.getTime();
        });

        setSortedEmployees(tempSortedEmployees);
      }
    }
  }, [business]);

  const handleServiceClick = (id: string | number) => {
    if (id === DEFAULT_TATTOO_SERVICE_ID) return;
    const url = business?.services?.find(service => service.serviceId === id)
      ?.bookingUrl;

    if (!url) return;

    navigate(url);
  };

  return (
    <Container maxWidth="md" className="shop-page" style={{ padding: 0 }}>
      {!error && (
        <Box>
          <TopImage images={business?.images} />
          <Box
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 20,
              marginBottom: 60,
            }}>
            <Box
              sx={{
                paddingBottom: '20px',
                borderBottom: 1,
                borderColor: 'lightGrey.main',
              }}>
              <Typography variant="h1">{business?.name}</Typography>
              {!!business?.city && !!business?.state && (
                <Typography variant="body1">
                  {business?.city}, {business?.state}
                </Typography>
              )}
            </Box>
            {business?.businessId !== 'cltg643tm0010s60ul6jdtkk6' && (
              <Box
                sx={{
                  paddingBottom: '20px',
                  borderBottom: 1,
                  borderColor: 'lightGrey.main',
                }}>
                <Typography variant="h2" style={{ marginBottom: 20 }}>
                  Location
                </Typography>
                <div>
                  <iframe
                    title="google-map-embed"
                    width="100%"
                    height="250"
                    frameBorder="0"
                    style={{ border: 0 }}
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=${PLACES_API_KEY}&q=${addressSearch}`}
                    allowFullScreen
                  />
                </div>
              </Box>
            )}
            {business && business.faqs && business.faqs.length > 0 && (
              <Box
                sx={{
                  paddingBottom: '20px',
                  borderBottom: 1,
                  borderColor: 'lightGrey.main',
                }}>
                <Typography variant="h2">FAQ</Typography>
                <FAQAccordion accordionItems={business.faqs} />
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}>
              {sortedEmployees && sortedEmployees.length > 0 ? (
                <div>
                  <Typography variant="h2">Book your tattoo</Typography>
                  <Typography variant="body2">Select an artist</Typography>
                </div>
              ) : null}

              {business?.allowGeneralRequests && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '20px',
                    paddingBottom: '20px',
                    borderBottom: 1,
                    borderColor: 'lightGrey.main',
                  }}
                  onClick={() => navigate(`/shop/${urlPath}/request`)}>
                  <img
                    className="artist-thumbnail"
                    src={business.thumbnailImage}
                    alt="Any artist"
                    style={{
                      width: 75,
                      height: 75,
                    }}
                  />
                  <Typography variant="body1" style={{ flexGrow: 1 }}>
                    Any Artist
                  </Typography>
                  <ChevronRightIcon sx={{ color: 'darkGrey.main' }} />
                </Box>
              )}
              {sortedEmployees?.map(
                employee =>
                  employee.isBookable && (
                    <ArtistRow employee={employee} shopUrl={urlPath || ''} />
                  ),
              )}
              <ServicesBookingList
                header="Select a service"
                subHeader={`What service do you want to book with ${business?.name}? If you're looking for a tattoo, select an artist above.`}
                services={business?.services}
                onClick={handleServiceClick}
              />
            </Box>
          </Box>
        </Box>
      )}

      {error && (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Typography variant="h1" sx={{ marginTop: '63px' }}>
            There was an error loading this shop. Please double check that
            you&apos;ve entered the URL in correctly, and if the error persists,
            reach out to our support team using the above.
          </Typography>
          <PorterFooter />
        </Box>
      )}
    </Container>
  );
}
