import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleIcon from '@mui/icons-material/Google';

interface CompletedReviewProps {
  entityName?: string;
  reviewLinkUrl?: string;
  wasPositive: boolean;
}

export default function CompletedReview({
  entityName,
  reviewLinkUrl,
  wasPositive,
}: CompletedReviewProps) {
  if (wasPositive) {
    return (
      <>
        <Box className="review-header">
          <div className="review-location">
            <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
            <Typography variant="body2" style={{ color: 'var(--medGrey)' }}>
              {entityName}
            </Typography>
          </div>
          <Typography variant="h1">
            {entityName} would appreciate if you review their business below
          </Typography>
          <Typography variant="body2">
            Submit your review on Google below!
          </Typography>
        </Box>
        <Button
          variant="contained"
          className="primary"
          href={reviewLinkUrl!}
          style={{
            backgroundColor: '#4285F4',
            color: '#ffffff',
            border: 'none',
            boxShadow: 'none',
            fontSize: '.9rem',
          }}
          startIcon={<GoogleIcon />}>
          Review on Google
        </Button>
      </>
    );
  }

  return (
    <Box className="review-header">
      <div className="review-location">
        <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
        <Typography variant="body2" style={{ color: 'var(--medGrey)' }}>
          {entityName}
        </Typography>
      </div>
      <Typography variant="h1">Thanks for your feedback!</Typography>
      <Typography variant="body2">
        We value and appreciate your feedback and hope that we can resolve any
        issues you had!
      </Typography>
    </Box>
  );
}
