import Typography from '@mui/material/Typography';
import { Box, Button, TextField } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoodExperience from './GoodExperience';
import { Employee } from '../../interfaces';

interface ReviewFormProps {
  entityName?: string;
  employee?: Employee | null;
  onPositiveReview: () => void;
  onNegativeReview: () => void;
  feedback: string;
  setFeedback: (feedback: string) => void;
  response: 'positive' | 'negative' | null;
  setResponse: (response: 'positive' | 'negative' | null) => void;
  error?: Error | null;
}

export default function ReviewForm({
  entityName,
  employee,
  onPositiveReview,
  onNegativeReview,
  feedback,
  setFeedback,
  response,
  setResponse,
  error,
}: ReviewFormProps) {
  const handleResponseChange = async (value: 'positive' | 'negative') => {
    if (value === 'positive') {
      await onPositiveReview();
    }

    setResponse(value);
  };

  return (
    <>
      {!response && (
        <GoodExperience
          entityName={entityName}
          employee={employee}
          handleResponseChange={handleResponseChange}
        />
      )}

      {response === 'negative' && (
        <>
          <Box className="review-header">
            <div className="review-location">
              <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
              <Typography variant="body2" style={{ color: 'var(--medGrey)' }}>
                {entityName}
              </Typography>
            </div>
            <Typography variant="h1">
              How could we improve your experience?
            </Typography>
            <Typography variant="body2">
              We really appreciate you taking the time to let us know how we can
              improve!
            </Typography>
          </Box>
          <TextField
            id="feedback"
            name="feedback"
            value={feedback}
            onChange={event => setFeedback(event.target.value)}
            multiline
            minRows={3}
            className="textarea"
          />
          <Button
            variant="contained"
            className="primary"
            onClick={onNegativeReview}>
            Submit Feedback
          </Button>
        </>
      )}

      {error && (
        <Typography variant="body2" className="error">
          Error logging feedback. Please refresh the page and try again
        </Typography>
      )}
    </>
  );
}
