import React from 'react';
import { AppContext, AppState } from '../../context/AppContext/context';

function useExistingAttribution() {
  const { state, dispatch } = React.useContext(AppContext);

  const set = React.useCallback(
    (payload: AppState['utm']) => {
      dispatch({
        type: 'SET_EXISTING_ATTRIBUTION',
        payload,
      });
    },
    [dispatch],
  );

  return [state.utm, set] as const;
}

export default useExistingAttribution;
