import * as React from 'react';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { differenceInYears, getDate, getMonth, getYear } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  Country,
  Value,
} from 'react-phone-number-input';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Employee, FormValues, FullIntakeQuestion } from '../../interfaces';
import FormHeader from './FormHeader';
import ResendCode from '../auth/ResendCode';
import useSignIn from '../../hooks/global/useSignIn';
import AuthCode from '../auth/AuthCode';
import useUser from '../../hooks/global/useUser';
import { ConfirmationResult } from '../../types/auth';
import useVerifyPhoneNumber from '../../hooks/global/useVerifyPhoneNumber';
import MarketingConsent from './MarketingConsent';

type Props = {
  formValues: FormValues;
  setFormValues: (values: FormValues) => void;
  nextStep: () => void;
  prevStep: () => void;
  employee: Employee | undefined;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),

  birthday: yup
    .date()
    .required('Birthday is required')
    .test(
      'birthday',
      'Must be 18 years or older',
      value => differenceInYears(new Date(), new Date(value)) >= 18,
    ),
});

function FinalDetails({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  employee,
  intakeQuestion,
}: Props) {
  const { user } = useUser();
  const theme = useTheme();

  const [month, setMonth] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');
  const [day, setDay] = React.useState<string>('');

  const [value, setValue] = React.useState<string>();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [userPhone, setUserPhone] = React.useState<string>();
  const [selectedCountry, setSelectedCountry] = React.useState<Country>('US');

  const [codeResult, setCodeResult] = React.useState('');
  const [loginStep, setLoginStep] = React.useState<'phone' | 'code'>('phone');
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();

  const [pronounsValid, setPronounsValid] = React.useState(false);

  const valid = value && isPossiblePhoneNumber(value);
  const codeValid = !!codeResult && codeResult.length === 6;

  const { isPending, checkVerificationMutation } = useVerifyPhoneNumber();

  const authenticate = React.useCallback(async () => {
    const result = await checkVerificationMutation.mutateAsync({
      phoneNumber: userPhone!,
      code: codeResult,
      confirmation: confirmationResult!,
    });
    return result;
  }, [checkVerificationMutation, codeResult, confirmationResult, userPhone]);

  const formik = useFormik({
    initialValues: {
      email: formValues.email,
      birthday: formValues.birthday,
      igHandle: formValues.igHandle,
      pronouns: formValues.pronouns,
      isMarketingOptIn: formValues.isMarketingOptIn,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async values => {
      if (!user && loginStep === 'code') {
        if (isPending) return;
        const authResult = await authenticate();
        if (!authResult) return;
      }

      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('email', values.email!);
      sessionStorage.setItem('birthday', JSON.stringify(values.birthday));
      if (values.igHandle) {
        sessionStorage.setItem('igHandle', values.igHandle!);
      }
      if (values.pronouns) {
        sessionStorage.setItem('pronouns', values.pronouns!);
      }
      sessionStorage.setItem(
        'isMarketingOptIn',
        (!!values.isMarketingOptIn).toString(),
      );

      nextStep();
    },
  });

  const signInMutation = useSignIn({
    onSuccess: response => {
      if ('error' in response) {
        setErrorMessage(response.error);
        setError(true);
        return;
      }
      setUserPhone(value);
      setConfirmationResult({
        confirmationType: 'firebase',
        ...response.confirmation,
      });
      setLoginStep('code');
      setError(false);
    },
  });

  const sendCode = React.useCallback(async () => {
    signInMutation.mutate(value);
  }, [signInMutation, value]);

  const questionValues = {
    header: intakeQuestion?.customQuestionTitle || 'Tell us about yourself',
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#333333',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: '20px',
    },
  }));

  React.useEffect(() => {
    if (formValues.birthday) {
      setMonth(JSON.stringify(getMonth(formValues.birthday) + 1)); // index is 0 based
      setYear(JSON.stringify(getYear(formValues.birthday)));
      setDay(JSON.stringify(getDate(formValues.birthday)));
    }
  }, [formValues.birthday]);

  const setFormikValue = (
    newMonth: string | undefined,
    newYear: string | undefined,
    newDay: string | undefined,
  ) => {
    if (newMonth && newYear && newDay) {
      formik.setFieldValue(
        'birthday',
        new Date(
          parseInt(newYear, 10),
          parseInt(newMonth, 10) - 1,
          parseInt(newDay, 10),
        ),
      );
      formik.setFieldTouched('birthday', true, false);
    }
  };

  const changeMonth = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMonth(event.target.value);
    setFormikValue(event.target.value, year, day);
  };

  const changeYear = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setYear(event.target.value);
    if (event.target.value.length === 4) {
      setFormikValue(month, event.target.value, day);
    }
  };

  const changeDay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDay(event.target.value);
    setFormikValue(month, year, event.target.value);
  };

  const handleChange = React.useCallback((val: Value) => {
    setValue(val);
  }, []);

  const handleCodeChange = (res: string) => {
    setCodeResult(res);
  };

  const codeErrorMessage = (
    <Typography variant="h3" color="primary" className="error">
      Code could not be verified. Please try again
    </Typography>
  );

  React.useEffect(() => {
    if (
      employee?.employeeId === 'clnc519vx004as60u93uy6pvc' ||
      employee?.employeeId === 'clix99ce5001ks60uygdf0xcr' ||
      employee?.employeeId === 'clwa2vh1i009nzzmgpoxhr3yt' ||
      employee?.employeeId === 'clx2j58jw002eq8qtyvh3826z' ||
      employee?.employeeId === 'clzan8ftq008i8mcr5jlog5ev' ||
      employee?.employeeId === 'cm4hv7hlj000ehs5ygf6ea9se' ||
      employee?.employeeId === 'cm0rla22t000o7dqbh1ckmv2v'
    ) {
      if (formik.values.pronouns) {
        setPronounsValid(true);
      } else {
        setPronounsValid(false);
      }
    } else {
      setPronounsValid(true);
    }
  }, [employee?.employeeId, formik.values.pronouns]);

  return (
    <form onSubmit={formik.handleSubmit} id="final-details">
      <div className="final-details-container">
        {loginStep === 'code' && (
          <div className="verify-code">
            <FormHeader
              header="What's the code?"
              subHeader={`Enter the code sent to ${userPhone}`}
            />
            <Box className="input-container no-gap">
              <AuthCode
                value={codeResult}
                onChange={handleCodeChange}
                disabled={isPending}
              />
              {checkVerificationMutation.isError ? codeErrorMessage : null}

              <ResendCode
                phone={userPhone}
                onSuccess={response => {
                  setConfirmationResult(response);
                }}
              />
            </Box>

            <BottomActionBar
              primaryText="Continue"
              primaryAction={formik.submitForm}
              primaryDisabled={!codeValid || isPending}
            />
          </div>
        )}
        {loginStep === 'phone' && (
          <div>
            <FormHeader
              header={questionValues.header}
              subHeader={questionValues.subHeader}
            />
            <Box
              className="multi-input-container"
              style={{ padding: '0px 16px' }}>
              {!user && (
                <div className="phone-input-container">
                  <span className="country-code">
                    +{getCountryCallingCode(selectedCountry)}
                  </span>
                  <PhoneInput
                    defaultCountry="US"
                    onCountryChange={country => {
                      if (country) {
                        setSelectedCountry(country);
                      }
                    }}
                    value={value}
                    onChange={handleChange}
                    countrySelectProps={{ unicodeFlags: false }}
                    placeholder="Phone number"
                  />
                </div>
              )}
              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {employee &&
                (employee.employeeId === 'clnc519vx004as60u93uy6pvc' ||
                  employee.employeeId === 'clix99ce5001ks60uygdf0xcr' ||
                  employee?.employeeId === 'clwa2vh1i009nzzmgpoxhr3yt' ||
                  employee?.employeeId === 'clx2j58jw002eq8qtyvh3826z' ||
                  employee?.employeeId === 'cm4hv7hlj000ehs5ygf6ea9se' ||
                  employee?.employeeId === 'clzan8ftq008i8mcr5jlog5ev' ||
                  employee?.employeeId === 'cm0rla22t000o7dqbh1ckmv2v') && (
                  <TextField
                    id="pronouns"
                    name="pronouns"
                    label="Preferred Pronouns"
                    type="text"
                    value={formik.values.pronouns}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pronouns && Boolean(formik.errors.pronouns)
                    }
                    helperText={
                      formik.touched.pronouns && formik.errors.pronouns
                    }
                  />
                )}
              <TextField
                id="igHandle"
                name="igHandle"
                label="Instagram Handle (optional)"
                type="text"
                value={formik.values.igHandle}
                onChange={formik.handleChange}
              />
              <div className="birthday-input">
                <div className="birthday-header">
                  <FormLabel className="birthday-label">Birthday</FormLabel>
                  <LightTooltip
                    title="To submit this request, you must be at least 18 years old with a valid ID"
                    arrow
                    id="birthday-tooltip"
                    placement="bottom-start">
                    <IconButton>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </div>
                <div className="birthday-inputs">
                  <TextField
                    id="month"
                    name="month"
                    className="month-input"
                    label="MM"
                    type="text"
                    value={month}
                    onChange={changeMonth}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 2,
                    }}
                  />
                  <TextField
                    id="day"
                    name="day"
                    className="day-input"
                    label="DD"
                    type="text"
                    value={day}
                    onChange={changeDay}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 2,
                    }}
                  />
                  <TextField
                    id="year"
                    name="year"
                    className="year-input"
                    label="YYYY"
                    type="text"
                    value={year}
                    onChange={changeYear}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 4,
                      minLength: 4,
                    }}
                  />
                </div>
              </div>

              <MarketingConsent
                checked={!!formik.values.isMarketingOptIn}
                onChange={formik.handleChange}
              />
            </Box>
            {user && (
              <BottomActionBar
                primaryText="Continue"
                isSubmit
                primaryDisabled={!formik.isValid || !pronounsValid}
                secondaryText="Back"
                secondaryAction={prevStep}
              />
            )}
            {!user && !signInMutation.isPending && (
              <BottomActionBar
                primaryText="Continue"
                primaryAction={sendCode}
                primaryDisabled={
                  !formik.isValid ||
                  signInMutation.isPending ||
                  !valid ||
                  isPending
                }
                secondaryText="Back"
                secondaryAction={prevStep}
              />
            )}
          </div>
        )}
        {formik.errors.birthday && formik.touched.birthday ? (
          <Typography variant="body1" className="error">
            {formik.errors.birthday}
          </Typography>
        ) : null}
        {formik.errors.email && formik.touched.email ? (
          <Typography variant="body1" className="error">
            {formik.errors.email}
          </Typography>
        ) : null}
        {error ? (
          <Typography variant="body1" color="primary" className="error">
            {errorMessage}
          </Typography>
        ) : null}
      </div>
    </form>
  );
}

export default FinalDetails;
