/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Box } from '@mui/material';
import AreaImages from './AreaImages';
import FinalDetails from './FinalDetails';
import Name from './Name';
import ReferenceImages from './ReferenceImages';
import Review from './Review';
import TattooColor from './TattooColor';
import TattooDetails from './TattooDetails';
import TattooInspiration from './TattooInspiration';
import TattooLocation from './TattooLocation';
import TattooSize from './TattooSize';
import FirstTattoo from './FirstTattoo';
import NewCustomer from './NewCustomer';
import Budget from './Budget';
import Styles from './Styles';
import './Form.css';
import {
  Employee,
  FormValues,
  Business,
  FullIntakeQuestion,
} from '../../interfaces';
import GeneralAvailability from './GeneralAvailability';
import Login from '../auth/Login';
import MedicalHistory from './MedicalHistory';
import SkinTone from './SkinTone';
import useUser from '../../hooks/global/useUser';
import OngoingProject from './OngoingProject';
import CoverUp from './CoverUp';
import TattooServiceType from './TattooServiceType';
import { FORM_STEPS } from '../../constants/form';
import SurroundingTattoos from './SurroundingTattoos';
import Ephemeral from './Epehemral';

type Props = {
  progress: string;
  setProgress: any;
  step: number;
  setStep: any;
  employee?: Employee | undefined;
  business?: Business | undefined;
  intakeQuestions: FullIntakeQuestion[] | undefined;
  setAppointmentCity?: React.Dispatch<React.SetStateAction<string>>;
  setAppointmentTimezone?: React.Dispatch<React.SetStateAction<string>>;
  appointmentCity?: string;
  appointmentTimezone?: string;
  askCity?: boolean;
  selectedArtists?: string[];
};

function Form({
  progress,
  setProgress,
  step,
  setStep,
  employee,
  business,
  intakeQuestions,
  appointmentCity,
  appointmentTimezone,
  setAppointmentCity,
  setAppointmentTimezone,
  askCity,
  selectedArtists,
}: Props) {
  const { user } = useUser();

  const [formValues, setFormValues] = React.useState(() => {
    // This code runs only once when the component mounts
    const formInitValues: FormValues = {
      businessId:
        sessionStorage.getItem('businessId') !== null
          ? sessionStorage.getItem('businessId')!
          : '',
      firstName:
        sessionStorage.getItem('firstName') !== null
          ? sessionStorage.getItem('firstName')!
          : '',
      lastName:
        sessionStorage.getItem('lastName') !== null
          ? sessionStorage.getItem('lastName')!
          : '',
      location:
        sessionStorage.getItem('location') !== null
          ? sessionStorage.getItem('location')!
          : '',
      details:
        sessionStorage.getItem('details') !== null
          ? sessionStorage.getItem('details')!
          : '',
      size:
        sessionStorage.getItem('size') !== null
          ? sessionStorage.getItem('size')!
          : '',
      color:
        sessionStorage.getItem('color') !== null
          ? sessionStorage.getItem('color')!
          : '',
      inspiration:
        sessionStorage.getItem('inspiration') !== null
          ? sessionStorage.getItem('inspiration')!
          : '',
      medicalHistory:
        sessionStorage.getItem('medicalHistory') !== null
          ? sessionStorage.getItem('medicalHistory')!
          : '',
      availability:
        sessionStorage.getItem('availability') !== null
          ? sessionStorage.getItem('availability')!
          : '',
      styles:
        sessionStorage.getItem('styles') !== null
          ? sessionStorage.getItem('styles')!
          : '',
      refImages: [],
      areaImages: [],
      email:
        sessionStorage.getItem('email') !== null
          ? sessionStorage.getItem('email')!
          : '',
      budget: sessionStorage.getItem('budget')
        ? parseInt(JSON.parse(sessionStorage.getItem('budget')!), 10)
        : undefined,
      otherNotes:
        sessionStorage.getItem('otherNotes') !== null
          ? sessionStorage.getItem('otherNotes')!
          : '',
      igHandle:
        sessionStorage.getItem('igHandle') !== null
          ? sessionStorage.getItem('igHandle')!
          : '',
      pronouns:
        sessionStorage.getItem('pronouns') !== null
          ? sessionStorage.getItem('pronouns')!
          : '',
      firstTattoo:
        sessionStorage.getItem('firstTattoo') !== null
          ? sessionStorage.getItem('firstTattoo')!
          : '',
      newCustomer:
        sessionStorage.getItem('newCustomer') !== null
          ? sessionStorage.getItem('newCustomer')!
          : '',
      ongoingProject:
        sessionStorage.getItem('ongoingProject') !== null
          ? sessionStorage.getItem('ongoingProject')!
          : '',
      coverUp:
        sessionStorage.getItem('coverUp') !== null
          ? sessionStorage.getItem('coverUp')!
          : '',
      surroundingTattoos:
        sessionStorage.getItem('surroundingTattoos') !== null
          ? sessionStorage.getItem('surroundingTattoos')!
          : '',
      isEphemeral:
        sessionStorage.getItem('isEphemeral') !== null
          ? sessionStorage.getItem('isEphemeral')!
          : '',
      tattooServiceType:
        sessionStorage.getItem('tattooServiceType') !== null
          ? sessionStorage.getItem('tattooServiceType')!
          : '',
      skinTone:
        sessionStorage.getItem('skinTone') !== null
          ? sessionStorage.getItem('skinTone')!
          : '',
      birthday:
        sessionStorage.getItem('birthday') !== null
          ? new Date(JSON.parse(sessionStorage.getItem('birthday')!))
          : undefined,
      isMarketingOptIn: sessionStorage.getItem('isMarketingOptIn')
        ? sessionStorage.getItem('isMarketingOptIn') === 'true'
        : true,
    };

    if (sessionStorage.getItem('refImageCount')) {
      const refImageCount = JSON.parse(
        sessionStorage.getItem('refImageCount')!,
      );
      for (let i = 0; i < refImageCount; i += 1) {
        const image = JSON.parse(sessionStorage.getItem(`refImage-${i}`)!);
        if (image) {
          formInitValues.refImages!.push(image);
        }
      }
    }

    if (sessionStorage.getItem('areaImageCount')) {
      const areaImageCount = JSON.parse(
        sessionStorage.getItem('areaImageCount')!,
      );
      for (let i = 0; i < areaImageCount; i += 1) {
        const image = JSON.parse(sessionStorage.getItem(`areaImage-${i}`)!);
        if (image) {
          formInitValues.areaImages!.push(image);
        }
      }
    }

    return formInitValues;
  });
  const [clickedEdit, setClickedEdit] = React.useState(false);
  const [backwards, setBackwards] = React.useState(false);
  const [nameStep, setNameStep] = React.useState(1);

  const nextStep = React.useCallback(() => {
    if (intakeQuestions !== undefined) {
      intakeQuestions.sort((a: any, b: any) => a.step - b.step);

      if (!clickedEdit) {
        for (let i = 0; i < intakeQuestions.length; i += 1) {
          if (
            i < intakeQuestions.length - 1 &&
            intakeQuestions[i].step === step
          ) {
            setStep(intakeQuestions[i + 1].step);
            sessionStorage.setItem(
              'step',
              JSON.stringify(intakeQuestions[i + 1].step),
            );
            if (intakeQuestions[i + 1].category !== progress) {
              setProgress(intakeQuestions[i + 1].category);
              sessionStorage.setItem(
                'progress',
                intakeQuestions[i + 1].category,
              );
            }
          }
        }

        if (step === 1 && intakeQuestions[0].step !== 1) {
          setStep(intakeQuestions[0].step);
          sessionStorage.setItem(
            'step',
            JSON.stringify(intakeQuestions[0].step),
          );

          setProgress(intakeQuestions[0].category);
          sessionStorage.setItem('progress', intakeQuestions[0].category);
        }
        setBackwards(false);
        window.scrollTo(0, 0);
      } else {
        setStep(intakeQuestions[intakeQuestions.length - 1].step);
        setClickedEdit(false);
        window.scrollTo(0, 0);
      }
    }
  }, [intakeQuestions, clickedEdit, step, setStep, progress, setProgress]);

  const prevStep = React.useCallback(() => {
    if (intakeQuestions !== undefined) {
      intakeQuestions.sort((a: any, b: any) => a.step - b.step);

      for (let i = 0; i < intakeQuestions.length; i += 1) {
        if (i > 0 && intakeQuestions[i].step === step) {
          setStep(intakeQuestions[i - 1].step);
          sessionStorage.setItem(
            'step',
            JSON.stringify(intakeQuestions[i - 1].step),
          );
          if (intakeQuestions[i - 1].category !== progress) {
            setProgress(intakeQuestions[i - 1].category);
            sessionStorage.setItem('progress', intakeQuestions[i - 1].category);
          }
        }
      }
      setBackwards(true);
      window.scrollTo(0, 0);
    }
  }, [intakeQuestions, setStep, progress, setProgress, step]);

  switch (step) {
    case FORM_STEPS.NAME:
      return (
        <Box
          style={{
            flex: '1 1 0',
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <Name
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            appointmentCity={appointmentCity}
            setAppointmentCity={setAppointmentCity}
            employee={employee}
            business={business}
            setAppointmentTimezone={setAppointmentTimezone}
            askCity={askCity}
            backwards={backwards}
            clickedEdit={clickedEdit}
            nameStep={nameStep}
            setNameStep={setNameStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.NAME,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_SERVICE_TYPE:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooServiceType
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_SERVICE_TYPE,
            )}
          />
        </Box>
      );

    case FORM_STEPS.FIRST_TATTOO:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <FirstTattoo
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.FIRST_TATTOO,
            )}
          />
        </Box>
      );

    case FORM_STEPS.NEW_CUSTOMER:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <NewCustomer
            formValues={formValues}
            setFormValues={setFormValues}
            employee={employee}
            business={business}
            nextStep={nextStep}
            prevStep={prevStep}
            backwards={backwards}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.NEW_CUSTOMER,
            )}
          />
        </Box>
      );

    case FORM_STEPS.COVER_UP:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <CoverUp
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.COVER_UP,
            )}
          />
        </Box>
      );

    case FORM_STEPS.ONGOING_PROJECT:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <OngoingProject
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.ONGOING_PROJECT,
            )}
          />
        </Box>
      );

    case FORM_STEPS.SURROUNDING_TATTOOS:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <SurroundingTattoos
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.SURROUNDING_TATTOOS,
            )}
          />
        </Box>
      );

    case FORM_STEPS.EPHEMERAL:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <Ephemeral
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.EPHEMERAL,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_LOCATION:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooLocation
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_LOCATION,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_SIZE:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooSize
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            employee={employee}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_SIZE,
            )}
            business={business}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_COLOR:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooColor
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_COLOR,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_DETAILS:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooDetails
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            employee={employee}
            business={business}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_DETAILS,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_INSPIRATION:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TattooInspiration
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_INSPIRATION,
            )}
          />
        </Box>
      );

    case FORM_STEPS.TATTOO_STYLES:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <Styles
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.TATTOO_STYLES,
            )}
          />
        </Box>
      );

    case FORM_STEPS.BUDGET:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <Budget
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            employee={employee}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.BUDGET,
            )}
          />
        </Box>
      );

    case FORM_STEPS.FINAL_DETAILS:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <FinalDetails
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.FINAL_DETAILS,
            )}
          />
        </Box>
      );

    case FORM_STEPS.SKIN_TONE:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <SkinTone
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.SKIN_TONE,
            )}
          />
        </Box>
      );

    case FORM_STEPS.GENERAL_AVAILABILITY:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <GeneralAvailability
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.GENERAL_AVAILABILITY,
            )}
          />
        </Box>
      );

    case FORM_STEPS.MEDICAL_HISTORY:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <MedicalHistory
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.MEDICAL_HISTORY,
            )}
          />
        </Box>
      );

    case FORM_STEPS.REFERENCE_IMAGES:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <ReferenceImages
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            business={business}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.REFERENCE_IMAGES,
            )}
          />
        </Box>
      );

    case FORM_STEPS.AREA_IMAGES:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <AreaImages
            formValues={formValues}
            setFormValues={setFormValues}
            nextStep={nextStep}
            prevStep={prevStep}
            intakeQuestion={intakeQuestions?.find(
              question => question.step === FORM_STEPS.AREA_IMAGES,
            )}
          />
        </Box>
      );

    case FORM_STEPS.REVIEW:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 153,
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          {user && (
            <Review
              formValues={formValues}
              employee={employee}
              business={business}
              setStep={setStep}
              setClickedEdit={setClickedEdit}
              appointmentCity={appointmentCity || ''}
              appointmentTimezone={appointmentTimezone || ''}
              setNameStep={setNameStep}
              selectedArtists={selectedArtists}
            />
          )}
          {!user && <Login />}
        </Box>
      );
    default:
      return (
        <div>
          There was an error loading questions for this Tattoo Artist. Try
          reloading the page and if there error persists, reach out to our
          support team for help
        </div>
      );
  }
}

export default Form;
